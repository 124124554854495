import $ from 'jquery';
import 'jquery-form';

psiac.submitInteractionContactRequest = (el) => {
    const $form = $(el);
    let action = $form.closest("form").attr('action');
    let interactionContact = $form.closest("form").find('#interaction_contact_text').val();

    if(interactionContact.length != 0) {
        $('[action="'+ action +'"]').submit(function (e) {
                e.preventDefault();
                let form = $(this);
                let url = form.attr('action').replace('create-interaction-contact', 'list-interaction-contact');
                const $content = form.offsetParent('.interaction-contact-list-display');
                const disableSubmit = () => form.find('#submit-interaction-contact-btn-container button').addClass('disabled');
                form.ajaxSubmit({
                    beforeSubmit: disableSubmit,
                    clearForm: true,
                    success: (result) => {
                        let message = "<div class=\"success\">" + result.message + " </div>";
                        $.get(url)
                                .then(function (data) {
                                    let htmlStr = "";
                                    htmlStr += message;
                                    htmlStr += data;
                                    $content.parent().html(htmlStr);
                                })
                                .fail(function () {
                                    let errorMsg = "\"<div class=\"error\">Der Inhalt konnte nicht geladen werden, laden Sie bitte die Seite neu und versuchen es nochmal.</div>";
                                    $content.parent().html(errorMsg);
                                })
                        ;
                    },
                    error: (response) => {
                        form.find('#interaction_contact').prepend("<div class=\"error\">Es ist ein Fehler aufgetreten. Bitte schicken Sie das Formular erneut ab.</div>");
                    }
                });
                return false;
            });
            return false;
        }
        return false;
    };

